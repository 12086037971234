import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { useSelector } from "react-redux"

import Pager from "@components/Pager"
import { EAppSettings } from "@pages/index"
import { EScrollDirection, IAppState } from "@store/App/state"

import iconMenuArrowDown from "@static/svg/menu-arrow-down.svg"
import iconMenuArrowUp from "@static/svg/menu-arrow-up.svg"

import style from "./Navigation.scss"

interface INavigationProps {
  onPagerClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
  const { onPagerClick } = props
  const scrollDirection = useSelector((state: IAppState) => state.scrollDirection)
  const activePage = useSelector((state: IAppState) => state.activePage)
  const [scrollStyle, setScrollStyle] = useState<object>({})

  useEffect(() => {
    switch (scrollDirection) {
      case EScrollDirection.DOWN:
        if (activePage !== EAppSettings.NUMBER_OF_PAGES)
        setScrollStyle({ height: "65.9%", transition: "all .7s" })
        break
      case EScrollDirection.UP:
        if (activePage !== 0)
          setScrollStyle({ top: 0, transition: "all .7s" })
        break
      case EScrollDirection.NONE:
        setScrollStyle({ transition: "all .7s" })
        break
    }
  }, [activePage, scrollDirection])

  return (
    <nav className={style.navigationSidebar}>
      {/* <div className={style.navigationSidebarTop}/> */}
      <div style={scrollStyle} className={style.sidebarFill}/>
      <div className={style.navigationSidebarSmall}>
        <div className={style.navigationSidebarSmallLine}/>
        <div className={style.navigationSidebarSmallLine}/>
        <div className={style.navigationSidebarSmallLine}/>
      </div>
      <div className={style.layerOne}>
        <SVG className={style.layerOneArrows} src={iconMenuArrowUp}/>
        <Pager pagerStyle={{ marginTop: "9rem" }} numberOfPages={EAppSettings.NUMBER_OF_PAGES + 1} onClick={onPagerClick}/>
        <SVG className={style.layerOneArrows} src={iconMenuArrowDown}/>
      </div>
    </nav>
  )
}

export default Navigation
