import React from "react"

import style from "./TitleHome.scss"

interface ITitleHome {
  id: string
  title: string,
  subtitle_part_one: string
  subtitle_part_two: string
  color: string
  anchorLink?: string
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const TitleHome: React.FunctionComponent<ITitleHome> = (props): any => {
  const { id, title, subtitle_part_one, subtitle_part_two, color, anchorLink, onClick } = props
  const aLink = !anchorLink ? "" : anchorLink

  return (
    <div className={style.titleContainer}>
      <a href={"#" + aLink} className={style.anchorStyle}>
        <div id={id} className={style.titleCircle} style={{ backgroundColor: color }} onClick={onClick}/>
      </a>
      <div className={style.titleTextContainer}>
        <div className={style.title}>
          {title}
        </div>
        <span className={style.titleSub}>
          {subtitle_part_one}
          <br/>
          {subtitle_part_two}
        </span>
      </div>
    </div>
  )
}

export default TitleHome
