import * as React from "react"

import { ESectionID } from "@pages/index"
import style from "./CoderPage.scss"

const CoderPage = () => {
  return (
    <div className={style.coderPage} id={ESectionID.CODER}>
      <div className={style.contentContainer}>
        <h1 className={style.contentTitle}>
          coder
        </h1>
        <div className={style.headerContainer}>
          <h2 className={style.headerLineOne}>
            overall experience: 8 years
          </h2>
          <h3 className={style.headingTitle}>stack</h3>
        </div>
        <h2 style={{ marginBottom: "1.7rem", textAlign: "left" }}>
          React.JS + Typescript, Redux, REST,
          GraphQL, Node.JS, Docker,
          Amazon AWS - CI/CD pipeline with ECR, ECS, EC2,
          Fargate, CloudFront, S3, Amplify
        </h2>
        <div className={style.paragraphSeparator}>{"{"}</div>
        <p className={style.paragraph} style={{ textAlign: "left" }}>
          I dedicate most of my time for product development
          as a software engineer. Although I enjoy most to
          oversee the full development lifecycle - including
          design research, UX/UI prototyping, development, and
          DevOps - I participate most in full-stack
          development.
        </p>
        <div className={style.paragraphSeparator}>{"{"}</div>
      </div>
    </div>
  )
}

export default CoderPage
