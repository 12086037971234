import * as React from "react"
import { useMediaQuery } from "react-responsive"

import StoryLine, { IStoryLineStyle } from "@components/StoryLine"
import { ESectionID } from "@pages/index"

import style from "./EntrepreneurPage.scss"

const EntrepreneurPage: React.FunctionComponent = () => {
  // const isDesktopOptimal = useMediaQuery({ query: `(max-width: ${style.desktopOptimal})` })
  const isPhoneM = useMediaQuery({ query: `(max-width: ${style.phoneM})` })
  const isPhoneL = useMediaQuery({ query: `(max-width: ${style.phoneL})` })
  const isTabletM = useMediaQuery({ query: `(max-width: ${style.tabletM})` })

  const getStyles = (storyLineStyle: IStoryLineStyle) => {
    if (isPhoneM) {
      return {
          card: { width: "100%" },
          arrow: { left: "20rem" },
          years: { top: "-0.2rem", left: "19rem", color: storyLineStyle.years.color }
      }
    }
    if (isPhoneL) {
      return {
          card: { width: "100%" },
          arrow: { left: "20rem" },
          years: { top: "1rem", left: "23rem", color: storyLineStyle.years.color }
      }
    }
    if (isTabletM) {
      return {
          card: { width: "100%" },
          arrow: { left: "20rem" },
          years: { top: "5rem", left: "30rem", color: storyLineStyle.years.color }
      }
    }
    return storyLineStyle
  }

  return (
    <div className={style.entrepreneurPage} id={ESectionID.ENTREPRENEUR}>
      <h1 className={style.heading}>entrepreneur</h1>
      <h3 className={style.storyCard}>TL;DR</h3>
      <div className={style.entrepreneurPageStory}>
        <StoryLine
          cardRowFirst="Co-Founder and"
          cardRowSecond="CEO at Digital Natives"
          dataYears="2 years"
          storyContent="During my term as a CEO, we moved financially from the near-zero profit level to 1.2 million USD in revenue, by establishing new businesses all over Europe and the US."
          styles={getStyles({
            card: { width: "32rem" },
            arrow: { left: "26.5rem" },
            years: { top: "-1rem", left: "20rem", color: style.colorYellow  },
          })}
        />
      </div>
      <div className={style.entrepreneurPageStory}>
        <StoryLine
          cardRowFirst="Co-Founder and"
          cardRowSecond="CEO at Eldacon"
          dataYears="7 years"
          storyContent="I led in-house software development projects and worked as a CEO, programmer, and DevOps engineer in Amazon AWS environment. Between 2006 and 2013, this company completed 100+ infrastructure projects and produced approx. USD 1 million annual revenue."
          styles={getStyles({
            card: { width: "25.7rem"},
            arrow: { left: "20rem" },
            years: { top: "3rem", left: "19rem", color: style.colorPurple }
          })}
        />
      </div>
      <div className={style.entrepreneurPageStory}>
        <StoryLine
          cardRowFirst="CTO and busines analyst"
          cardRowSecond="at Organica Water"
          dataYears="4 years"
          storyContent="I was responsible to design business processes, then implement and manage an ERP system capable of handling business transactions and company operations in Europe, the US, India, and China."
          styles={getStyles({
            card: { width: "30rem"},
            arrow: { left: "24rem" },
            years: { top: "1rem", left: "22rem", color: style.colorGreen  },
          })}
        />
      </div>
      <div className={style.bgImageContainer}/>
    </div>
  )
}

export default EntrepreneurPage
