import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"

import TitleHome from "@components/TitleHome"
import { ESectionID } from "@pages/index"
import style from "./HomePage.scss"

export enum HomePageTargets {
  DEV = "1",
  COACH = "2",
  ENTREPRENEUR = "3"
}
interface IHomePageProps {
  onTextClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  onCircleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
  const { onTextClick, onCircleClick } = props
  const isTabletM = useMediaQuery({ query: `(max-width: ${style.tabletM})` })

  const contentData = useStaticQuery(graphql`
    query {
      contentYaml(page: {eq: "HomePage"}) {
        content {
          title_1 {
            title
            subTitle_line_1
            subTitle_line_2
          }
          title_2 {
            title
            subTitle_line_1
            subTitle_line_2
          }
          title_3 {
            title
            subTitle_line_1
            subTitle_line_2
          }
        }
      }
    }
  `)

  return (
    <div className={style.homePage}>
      <div className={style.bgImage}/>
      {
        isTabletM ?
          <div className={style.headingContainer}>
            <h2 className={`${style.h2} ${style.h2Top}`}>
              hi, i’m szabolcs farkas ~ <span className={style.headingSpan}> szabi /sɑbɪ/</span>
            </h2>
          </div>
          : null
      }
      <div className={style.contentContainer}>
        {
          !isTabletM ?
            <div className={style.headingContainer}>
              <h2 className={`${style.h2} ${style.h2Top}`}>
                hi, i’m szabolcs farkas ~ <span className={style.headingSpan}> szabi /sɑbɪ/</span>
              </h2>
              <h2 className={style.h2}>
                click <span className={style.headingSpan} onClick={onTextClick}>here</span> to check what will we do together
              </h2>
            </div>
            : null
        }
        <TitleHome
          id={HomePageTargets.DEV}
          title={contentData.contentYaml.content.title_1.title}
          subtitle_part_one={contentData.contentYaml.content.title_1.subTitle_line_1}
          subtitle_part_two={contentData.contentYaml.content.title_1.subTitle_line_2}
          color={style.colorYellow}
          anchorLink={isTabletM ? ESectionID.CODER : null}
          onClick={onCircleClick}
        />
        <TitleHome
          id={HomePageTargets.COACH}
          title={contentData.contentYaml.content.title_2.title}
          subtitle_part_one={contentData.contentYaml.content.title_2.subTitle_line_1}
          subtitle_part_two={contentData.contentYaml.content.title_2.subTitle_line_2}
          color={style.colorGreen}
          anchorLink={isTabletM ? ESectionID.COACH : null}
          onClick={onCircleClick}
        />
        <TitleHome
          id={HomePageTargets.ENTREPRENEUR}
          title={contentData.contentYaml.content.title_3.title}
          subtitle_part_one={contentData.contentYaml.content.title_3.subTitle_line_1}
          subtitle_part_two={contentData.contentYaml.content.title_3.subTitle_line_2}
          color={style.colorPurple}
          anchorLink={isTabletM ? ESectionID.ENTREPRENEUR : null}
          onClick={onCircleClick}
        />
      </div>
    </div>
  )
}

export default HomePage
