import * as React from "react"
import { useMediaQuery } from "react-responsive"

import { ESectionID } from "@pages/index"
import style from "./CoachPage.scss"

const CoachPage: React.FunctionComponent = () => {
  const isUnderIpadMiniLand = useMediaQuery({ query: `(max-width: ${style.tabletM})` })

  const infoContainer = () => {
    return (
      <div className={style.contentContainerRight} id={ESectionID.COACH}>
        <div className={style.infoContainer}>
          <div className={style.infoContainerRowOne}>
            <p className={style.infoContainerRowOneLeft}>50+</p>
            <p className={style.infoContainerRowOneRight}>workshops since 2016</p>
          </div>
          <div className={style.infoContainerRowTwo}>
            <div className={style.infoContainerRowTwoLeft}>companies and NGO's from countries</div>
            <div className={style.infoContainerRowTwoLeftAbs}>4</div>
            <div className={style.infoContainerRowTwoRight}>
              <p className={style.infoContainerRowTwoRightTextOne}>average</p>
              <p className={style.infoContainerRowTwoRightTextTwo}>NPS > 9</p>
            </div>
          </div>
        </div>
        <p className={style.textContainer}>
          As a coach, I offer my experience to help in creating and managing self-driven and flat organization structures.
          Amongst a wide range of tools, I use agile games, the lean business canvas, the value proposition map,
          and the pirate framework to support my clients' awareness and business growth.
        </p>
      </div>
    )
}

  return (
    <div className={style.coachPage}>
      <div className={style.contentContainerLeft}>
        <div className={style.contentContainerLeftHeader}>
          <h1 style={{ marginBottom: "5.1rem" }}>agile <span className={style.titleSub}>coach</span></h1>
          <h2 style={{ marginBottom: ".7rem" }}>
            overall experience: 3 years
          </h2>
          <h2 style={{ marginBottom: ".7rem" }}>
            product development trainings
          </h2>
          <h2 style={{ marginBottom: ".7rem" }}>
            agile transformations
          </h2>
        </div>
        {
          isUnderIpadMiniLand
            ? infoContainer()
            : null
        }
        <div className={style.contentContainerLeftImgHolder}>
          <div className={style.floatElement}/>
        </div>
      </div>
      {
        !isUnderIpadMiniLand
          ? infoContainer()
          : null
      }
    </div>
  )
}

export default CoachPage
