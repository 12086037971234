import * as React from "react"
import SVG from "react-inlinesvg"

import iconArrowRigth from "@static/svg/arrow-right.svg"

import style from "./StoryLine.scss"

export interface IElementStyle {
  top?: string
  left?: string
  width?: string
  height?: string
  color?: string
}

export interface IStoryLineStyle {
  card?: IElementStyle,
  arrow: IElementStyle,
  years?: IElementStyle,
}

interface IStoryLineProps {
  cardRowFirst: string
  cardRowSecond: string
  dataYears: string
  storyContent: string
  styles: IStoryLineStyle
}

const StoryLine: React.FunctionComponent<IStoryLineProps> = (props) => {
  const {
    cardRowFirst,
    cardRowSecond,
    dataYears,
    storyContent,
    styles,
  } = props

  return (
    <div className={style.storyLine}>
      <h5
        style={{
          top: styles.years.top,
          left: styles.years.left,
          color: styles.years.color,
        }}
        className={style.dataYears}
      >
        {dataYears}
      </h5>
      <div style={{ width: styles.card.width }}  className={style.storyLineTitleCard}>
        <h2>
          {cardRowFirst}
          <br></br>
          {cardRowSecond}
        </h2>
        <SVG
          style={{ left: props.styles.arrow.left }}
          className={style.storyLineArrow}
          src={iconArrowRigth}
        />
      </div>
      <p className={style.storyLineContent}>{storyContent}</p>
    </div>
  )
}

export default StoryLine
