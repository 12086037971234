import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSwipeable } from "react-swipeable"
import { useMediaQuery } from "react-responsive"
import { Helmet } from "react-helmet"

import { AppActionCreators, AppActions } from "@store/App/actions"
import { EScrollDirection, IAppState } from "@store/App/state"

import Navigation from "@components/Navigation"
// import PageDivider from "@components/PageDivider"

import CoachPage from "@pages/CoachPage"
import CoderPage from "@pages/CoderPage"
// import CoderReferencesPage from "@pages/CoderReferencesPage"
import ConnectPage from "@pages/ConnectPage"
import DoTogehterPage from "@pages/DoTogetherPage"
import EntrepreneurPage from "@pages/EntrepreneurPage"
import HomePage, { HomePageTargets } from "@pages/HomePage"
import LoveToDoPage from "@pages/LoveToDoPage"

import style from "./index.scss"

export enum EAppSettings {
  NUMBER_OF_PAGES = 6,
  // in miliseconds
  TRANSITION_TIME_BETWEEN_PAGES = 1000,
}

export enum ESectionID {
  CODER = "coder",
  COACH = "coach",
  ENTREPRENEUR = "entrepreneur"
}

interface IPageStyle {
  pageID: number,
  newStyle: object
}

const IndexPage: React.FunctionComponent = () => {
  const [arrayDeltaY, setArrayDeltaY] = useState<number[]>([])
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false)
  const [pageStyles, setPageStyles] = useState<object[]>([])

  const maxPageCounter: number = EAppSettings.NUMBER_OF_PAGES
  const appTransitionTime: number = EAppSettings.TRANSITION_TIME_BETWEEN_PAGES

  const activePage = useSelector((state: IAppState) => state.activePage)
  const scrollDirection = useSelector((state: IAppState) => state.scrollDirection)
  const dispatch = useDispatch()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const isTabletM = useMediaQuery({ query: `(max-width: ${style.tabletM})` })

  useEffect(() => {
    // This is the first point when we have access to the document window
    setPageStyles((array) => [...array, { top: "0px", zIndex: 0 }])
    for (let ax = 1; ax <= maxPageCounter; ax++) {
      setPageStyles((array) => [
        ...array,
        {
          top: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) + "px",
          zIndex: ax,
        },
      ])
    }
    setIsLoaded(true)
  }, [])  // eslint-disable-line

  useEffect(() => {


    if (!isTimerRunning && scrollDirection === EScrollDirection.DOWN && activePage < maxPageCounter) {
      const newStyles: IPageStyle[] = []
      for (let ax = 0; ax <= activePage; ax++) {
        newStyles.push({ pageID: ax, newStyle: { top: -1 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0), zIndex: ax } })
      }
      newStyles.push({ pageID: activePage + 1, newStyle: { top: "0px", zIndex: activePage + 1 } })
      changePageStyles(newStyles)
      dispatch(AppActionCreators.setActivePage(activePage + 1))

      setTimeout(() => {
        dispatch(AppActions.setScrollDirection(EScrollDirection.NONE))
        setIsTimerRunning(false)
      }, appTransitionTime)
      setIsTimerRunning(true)
    }
    if (!isTimerRunning && scrollDirection === EScrollDirection.UP && activePage >= 1) {
      const newStyles: IPageStyle[] = []
      for (let ax = 0; ax <= maxPageCounter; ax++) {
        if (ax < activePage)
          newStyles.push({ pageID: ax, newStyle: { top: (-1 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) + "px", zIndex: ax } })
        else // if (ax > activePage)
          newStyles.push({ pageID: ax, newStyle: { top: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) + "px", zIndex: ax } })
      }
      newStyles.push({ pageID: activePage - 1, newStyle: { top: "0px", zIndex: activePage - 1 } })
      changePageStyles(newStyles)
      dispatch(AppActionCreators.setActivePage(activePage - 1))

      setTimeout(() => {
        dispatch(AppActions.setScrollDirection(EScrollDirection.NONE))
        setIsTimerRunning(false)
      }, appTransitionTime)
      setIsTimerRunning(true)
    }
  }, [scrollDirection])   // eslint-disable-line

  const changePageStyles = (styles: IPageStyle[]) => {
    const newPageStyles: object[] = [...pageStyles]
    styles.map((pageStyle) => {
      newPageStyles[pageStyle.pageID] = pageStyle.newStyle
    })
    setPageStyles(newPageStyles)
  }

  const isArrayStrictIncreasing = (myArray: number[]): boolean => {
    for (let ax = 1; ax <= myArray.length - 1; ax++) {
      if (Math.abs(myArray[0]) > Math.abs(myArray[ax])) { return false }
    }
    return true
  }

  const divOnWheel = (e: any) => {
    e.stopPropagation()
    const deltaY = e.deltaY
    // console.log(deltaY)
    if (Math.abs(deltaY) > 250) {
      // Mouse
      if (deltaY > 0) {
        dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN))
      } else {
        dispatch(AppActions.setScrollDirection(EScrollDirection.UP))
      }
    } else {
      // Touchpad
      if (arrayDeltaY.length <= 8) {
        setArrayDeltaY((myArray) => [...myArray, deltaY])
      } else {
        // console.log("Array has built")
        if (arrayDeltaY[0] < 40) {
          // console.log("Check is happening: ", arrayDeltaY[0])
          if (deltaY > 0) {
            if (isArrayStrictIncreasing(arrayDeltaY)) {
              // console.log("Checked, scroll DOWN is valid")
              dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN))
            } else {
              // console.log("Checked, down is not valid")
            }
          } else {
            if (isArrayStrictIncreasing(arrayDeltaY)) {
              // console.log("Checked, scroll UP is valid")
              dispatch(AppActions.setScrollDirection(EScrollDirection.UP))
            } else {
              // console.log("Checked, up is not valid")
            }
          }
        }
        setArrayDeltaY([])
      }
    }
  }

  const onHomePageTextClick = () => {
    dispatch(AppActionCreators.setActivePage(4))
    dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN))
  }

  const onHomePageCircleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    switch (event.currentTarget.id) {
      case HomePageTargets.DEV: dispatch(AppActionCreators.setActivePage(0))
      break
      case HomePageTargets.COACH: dispatch(AppActionCreators.setActivePage(1))
      break
      case HomePageTargets.ENTREPRENEUR: dispatch(AppActionCreators.setActivePage(2))
      break
    }
    dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN))
  }

  const onConnectPageClick = () => {
    dispatch(AppActionCreators.setActivePage(5))
    dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN))
  }

  const onPagerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const toPageNumber = parseFloat(event.currentTarget.id)

    if (toPageNumber < activePage) {
      dispatch(AppActionCreators.setActivePage(toPageNumber + 1))
      dispatch(AppActions.setScrollDirection(EScrollDirection.UP))
    }
    else if (toPageNumber > activePage) {
      dispatch(AppActionCreators.setActivePage(toPageNumber - 1))
      dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN))
    }
  }

  const handlers = useSwipeable({
    onSwipedUp: () => dispatch(AppActions.setScrollDirection(EScrollDirection.DOWN)),
    onSwipedDown: () => dispatch(AppActions.setScrollDirection(EScrollDirection.UP)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  })

  if (!isLoaded) { return null }

  const getAppPage = () => {
    if (isTabletM) {
      return (
        <React.Fragment>
          <HomePage onTextClick={onHomePageTextClick} onCircleClick={onHomePageCircleClick}/>
          <CoderPage/>
          <CoachPage/>
          <EntrepreneurPage/>
          <LoveToDoPage/>
          <DoTogehterPage onConnectClick={onConnectPageClick}/>
          <ConnectPage/>
        </React.Fragment>
      )
    }

    return (
      <div className={style.app} onWheel={divOnWheel} {...handlers}>
        <section className={`${style.contentContainer} ${style.contentBackground}`}>
          <section className={style.contentContainer}>
            <div className={style.navContainer}>
              <Navigation onPagerClick={onPagerClick}/>
            </div>
            <div style={pageStyles[0]} className={style.mainContentContainer}>
              <HomePage onTextClick={onHomePageTextClick} onCircleClick={onHomePageCircleClick}/>
            </div>
            <div style={pageStyles[1]} className={style.mainContentContainer}>
              <CoderPage/>
            </div>
            <div style={pageStyles[2]} className={style.mainContentContainer}>
              <CoachPage/>
            </div>
            <div style={pageStyles[3]} className={style.mainContentContainer}>
              <EntrepreneurPage/>
            </div>
            <div style={pageStyles[4]} className={style.mainContentContainer}>
              <LoveToDoPage/>
            </div>
            <div style={pageStyles[5]} className={style.mainContentContainer}>
              <DoTogehterPage onConnectClick={onConnectPageClick}/>
            </div>
            <div style={pageStyles[6]} className={style.mainContentContainer}>
              <ConnectPage/>
            </div>
          </section>
        </section>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>szabolcs farkas ~ szabi /sɑbɪ/</title>
        <meta name="title" content="szabolcs farkas ~ szabi /sɑbɪ/"/>>
        <meta name="description" content="Welcome to my portfolio site. If you like to work with me, just send an email or hit the WhatsApp logo at the end of the page."/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.easymonday.org/"/>
        <meta property="og:title" content="szabolcs farkas ~ szabi /sɑbɪ/"/>
        <meta property="og:description" content="Welcome to my portfolio site. If you like to work with me, just send an email or hit the WhatsApp logo at the end of the page."/>
        <meta property="og:image" content="http://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png"/>
        <meta property="og:image:secure_url" content="https://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png" />

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="http://www.easymonday.org/"/>
        <meta property="twitter:title" content="szabolcs farkas ~ szabi /sɑbɪ/"/>
        <meta property="twitter:description" content="Welcome to my portfolio site. If you like to work with me, just send an email or hit the WhatsApp logo at the end of the page."/>
        <meta property="twitter:image" content="http://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png"/>
        <meta property="twitter:image:secure_url" content="https://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png" />
      </Helmet>
      { getAppPage() }
    </React.Fragment>
  )

}

export default IndexPage

/*

        <title>szabolcs farkas ~ szabi /sɑbɪ/</title>
        <meta name="title" content="szabolcs farkas ~ szabi /sɑbɪ/"/>>
        <meta name="description" content="Welcome to my portfolio site. If you like to work with me, just send an email or hit the WhatsApp logo at the end of the page."/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.easymonday.org/"/>
        <meta property="og:title" content="szabolcs farkas ~ szabi /sɑbɪ/"/>
        <meta property="og:description" content="Welcome to my portfolio site. If you like to work with me, just send an email or hit the WhatsApp logo at the end of the page."/>
        <meta property="og:image" content="http://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png"/>
        <meta property="og:image:secure_url" content="https://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png" />

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="http://www.easymonday.org/"/>
        <meta property="twitter:title" content="szabolcs farkas ~ szabi /sɑbɪ/"/>
        <meta property="twitter:description" content="Welcome to my portfolio site. If you like to work with me, just send an email or hit the WhatsApp logo at the end of the page."/>
        <meta property="twitter:image" content="http://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png"/>
        <meta property="twitter:image:secure_url" content="https://easymonday.org/static/old-computer-7e2fad9d5d8fe656fbf51607bf3a28ec.png" />

*/
