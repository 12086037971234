import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { EScrollDirection, IAppState } from "@store/App/state"

import style from "./Pager.scss"

interface IPagerProps {
  numberOfPages: number
  pagerStyle?: object
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Pager: React.FunctionComponent<IPagerProps> = (props) => {
  const { numberOfPages, pagerStyle, onClick } = props
  const activePage = useSelector((state: IAppState) => state.activePage)
  const scrollDirection = useSelector((state: IAppState) => state.scrollDirection)
  const [arrayOfPageMarkers, setArrayOfPageMarkers] = useState([])

  useEffect(() => {
    const tmpArray = []
    let tmpClassName = ""
    for (let ax = 0; ax <= numberOfPages - 1; ax++) {
      (ax === activePage)
        ? tmpClassName = `${style.pagerCircle} ${style.pagerCircleActive}`
        : tmpClassName = `${style.pagerCircle} ${style.pagerCircleInactive}`

        tmpArray.push(<div id={ax.toString()} key={ax} className={tmpClassName} onClick={onClick}/>)
    }
    setArrayOfPageMarkers(tmpArray)
  }, [activePage])  // eslint-disable-line

  return (
    <div style={pagerStyle} className={style.pager}>
      {arrayOfPageMarkers}
    </div>
  )
}

export default Pager
