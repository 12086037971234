import * as React from "react"

import style from "./Bullet.scss"

export interface IBulletProps {
  top: string
  left: string
  bgColor: string
}

const Bullet: React.FunctionComponent<IBulletProps> = (props) => {
  return (
    <div
      style={{
        top: props.top,
        left: props.left,
        backgroundColor: `${props.bgColor}`,
      }}
      className={style.bullet}
    />
  )
}

export default Bullet
