import React from "react"
import { useMediaQuery } from "react-responsive"

import Bullet, { IBulletProps } from "@components/Bullet"

import style from "./LoveToDoPage.scss"

const LoveToDoPage: React.FunctionComponent = () => {
  const isTabletM = useMediaQuery({ query: `(max-width: ${style.tabletM})` })

  const setBullet = ( bulletStyle: IBulletProps ) => {
    if (isTabletM) {
      return <Bullet top={bulletStyle.top} left={"-1rem"} bgColor={bulletStyle.bgColor}/>
    }
    return <Bullet { ...bulletStyle }/>
  }

  return (
    <div className={style.loveToDoPage}>
      <div className={`${style.contentContainers} ${style.contentContainersLeft}`}>
      <h1
        style={{ marginBottom: "4rem" }}
      >
        what I<br/><span className={style.titleSub}>love to do</span>
      </h1>
        <div className={style.textContainer}>
          {setBullet({ top: "1.5rem", left: "-2.5rem", bgColor: style.colorYellow })}
          <p>build full-stack apps in React and NodeJS</p>
          <p>create development workflows, CI/CD pipelines</p>
          <p>create web-product designs</p>
        </div>
        <div className={style.textContainer}>
          {setBullet({ top: "3.5rem", left: "-2.5rem", bgColor: style.colorPurple })}
          <p>build products from scratch or scale-it up</p>
          <p>found scalable business models</p>
          <p>scale up teams</p>
          <p>defining business workflows</p>
        </div>
        <div className={style.textContainer}>
          {setBullet({ top: "3.5rem", left: "-2.5rem", bgColor: style.colorGreen })}
          <p>hold workshops and train colleagues</p>
          <p>facilitate meetings</p>
          <p>help forming organization culture</p>
          <p>coach teams and individuals</p>
        </div>
      </div>
      <div className={`${style.contentContainers} ${style.contentContainersRight}`}>
      <h1
        style={{
          marginBottom: "4rem",
          color: `${style.colorGreen}`,
        }}
      >
        what I am<br/><span className={style.titleSub}>looking for</span>
      </h1>
        <div className={style.textContainer}>
          {setBullet({ top: "1.5rem", left: "-2.5rem", bgColor: style.colorYellow })}
          <p>build products that people love to use</p>
          <p>code as full-stack developer</p>
          <p>opportunities to work with and to learn from smarter people</p>
          <p>manage, coach, scale-up teams</p>
        </div>
        <div className={style.textContainer}>
          {setBullet({ top: "1.5rem", left: "-2.5rem", bgColor: style.colorGreen })}
          <p>early stage startups / scaleups / spinoffs</p>
          <p>small dev agencies</p>
          <p className={style.highlight}>true purpose: #future societies, #people</p>
          <p className={style.highlight}>#environment, #green-tech</p>
        </div>
      </div>
      <div className={style.bgImageContainer}/>
    </div>
  )
}

export default LoveToDoPage
